@font-face {
  font-family: 'apercu-bold-pro';
  src: url('/fonts/apercu-bold-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-medium-pro';
  src: url('/fonts/apercu-medium-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-mono-regular-pro';
  src: url('/fonts/apercu-mono-regular-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-regular-pro';
  src: url('/fonts/apercu-regular-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
